import api from '@/base/utils/request'
// 获取首页组件库
export const getBigDataTemplates = function() {
  return api({
    url: '/admin/data_view/homePage/componentList',
    method: 'post'
  })
}
// 获取首页配置
export const getHome = () => {
  return api({
    url: '/admin/data_view/homePage/getComponentConfig',
    method: 'post'
  })
}
// 保存首页配置
export const saveHomeBigData = (data) => {
  return api({
    url: '/admin/data_view/homePage/saveComponentConfig',
    method: 'post',
    data
  })
}

// 获取会员分析组件库
export const getMemberStatsTemplates = function() {
  return api({
    url: '/admin/data_view/MemberStats/componentList',
    method: 'post'
  })
}
// 获取会员分析配置
export const getMemberStats = () => {
  return api({
    url: '/admin/data_view/memberStats/getComponentConfig',
    method: 'post'
  })
}
// 保存会员分析配置
export const saveMemberStats = (data) => {
  return api({
    url: '/admin/data_view/memberStats/saveComponentConfig',
    method: 'post',
    data
  })
}

// 获取财务分析组件库
export const getOrderStatsTemplates = function() {
  return api({
    url: '/admin/data_view/orderStats/componentList',
    method: 'post'
  })
}
// 获取财务分析配置
export const getOrderStats = () => {
  return api({
    url: '/admin/data_view/orderStats/getComponentConfig',
    method: 'post'
  })
}
// 保存财务分析配置
export const saveOrderStats = (data) => {
  return api({
    url: '/admin/data_view/orderStats/saveComponentConfig',
    method: 'post',
    data,
  })
}