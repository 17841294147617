import { api } from "@/base/utils/request";

export const getOverviewPageConfig = () => {
  return api({
    url: '/admin/data_view/settings/getDataViewConfig',
    method: 'post'
  })
}

export const saveOverviewPageConfig = (data) => {
  return api({
    url: '/admin/data_view/settings/setDataViewConfig',
    method: 'post',
    data
  })
}
