<template>
  <div class="container fullscreen" v-loading="loading" id="layer-box">
    <div class="origin" :style="{}">
      <div
        class="header flex-between"
        :style="{
          width: windowWidth / scaleRatioByHeight + 'px',
          transform: `scale(${
            scaleRatioByHeight > 1 ? 1 : scaleRatioByHeight
          })`,
        }"
      >
        <div class="left flex flex-align-center">
          <img class="logo" :src="logo" />
          <img class="title" src="../assets/h1.png" />
        </div>
        <div class="middle flex-align-center">
          <div
            class="page-btn flex-col"
            :class="[currentTabPage === 'home' ? 'active' : '']"
            @click="switchTab('home')"
          >
            首页
          </div>
          <div
            class="page-btn flex-col"
            :class="[currentTabPage === 'memberStats' ? 'active' : '']"
            @click="switchTab('memberStats')"
          >
            会员分析
          </div>
          <div
            class="page-btn flex-col"
            :class="[currentTabPage === 'orderStats' ? 'active' : '']"
            @click="switchTab('orderStats')"
          >
            财务分析
          </div>
      
        </div>
        <div class="right flex">
          <div
            class="btn flex-col flex-center cursor-pointer"
            @click="$message.info('敬请期待')"
          >
            <img class="icon" src="../assets/download.png" />
            <!--          <i class="el-icon-printer" />-->
            <span class="text">导出数据</span>
          </div>
          <div
            class="btn flex-col flex-center cursor-pointer"
            @click="toDesign()"
          >
            <img class="icon" src="../assets/decorate.png" />
            <!--          <i class="el-icon-brush" />-->
            <span class="text">页面装修</span>
          </div>
          <!--          <div class="btn flex-col flex-center cursor-pointer" @click="showSetting = true">-->
          <!--            <i class="el-icon el-icon-setting"/>-->
          <!--            <span class="text">设置</span>-->
          <!--          </div>-->
          <div
            class="btn flex-col flex-center cursor-pointer"
            @click="toggleFullscreen"
          >
            <i class="el-icon el-icon-full-screen" />
            <span class="text">全屏</span>
          </div>
          <div
            class="btn flex-col flex-center cursor-pointer"
            @click="closeWindow()"
          >
            <!--          <img class="icon" />-->
            <i class="el-icon el-icon-close" />
            <span class="text">关闭</span>
          </div>
        </div>
      </div>
      <div
        class="header-copy"
        :style="{
          height: 88 * (scaleRatioByHeight > 1 ? 1 : scaleRatioByHeight) + 'px',
        }"
      />
      <!--      <el-empty description="暂无内容" style="margin-top: 15vh;">-->
      <!--        <p slot="description">暂无内容，点击前往<el-link class="cursor-pointer" @click="toDesign">页面装修</el-link>设置</p>-->
      <!--      </el-empty>-->
      <div
        v-if="!dataList.length"
        class="flex-center"
        :style="{
          height: `calc(100vh - ${
            88 * (scaleRatioByHeight > 1 ? 1 : scaleRatioByHeight)
          }px)`,
        }"
      >
        <el-button type="plain" @click="toDesign()">页面装修</el-button>
      </div>

      <div
        class="layer"
        id="content-box"
        :style="{
          width: colWidth ? `${gridWidth}px` : '',
          // 用 windowHeight 算出 row-height，从而推算出 row-width，如果 grid-width 超过 windowWidth，就需要进行缩放啦
          transform:
            colWidth && windowHeight && gridWidth > windowWidth
              ? `scale(${windowWidth / gridWidth})`
              : '',
          // 居中
          marginLeft: (windowWidth - gridWidth) / 2 + 'px',
        }"
      >
        <!--
        :row-height="colHeight || ((windowHeight - 61 - 9 * 4) / 3)"
        -->
        <grid-layout
          id="content"
          ref="gridlayout"
          class="stage grid"
          :layout.sync="dataList"
          :col-num="colNum"
          :row-height="colHeight"
          :is-draggable="false"
          :is-resizable="false"
          :is-mirrored="false"
          :is-bounded="true"
          :vertical-compact="true"
          :margin="[gap, gap]"
          :use-css-transforms="true"
          :max-rows="3"
          :auto-size="true"
          :style="{
            // width: colWidth ? `${colWidth * 4 + 9 * 5}px` : '',
            // marginLeft: -(1920 - ((colWidth * 4 + 9 * 5))) + 'px'
          }"
        >
          <div
            class="before"
            :style="{
              backgroundSize: `calc(calc(100% - ${gap}px) / 4) ${
                (colWidth * 181) / 269 + gap
              }px`,
            }"
          />
          <!--                <draggable v-model="dataList" :options="dragOptions" style="height: 100%;">-->
          <template v-for="(item, dI) in dataList">
            <grid-item
              :x="item.x"
              :y="item.y"
              :w="item.w"
              :h="item.h"
              :i="item.i"
              :key="item.i"
            >
              <div class="stage-item">
                <!--                      <draggable v-model="dataList[dI].list" class="drag-item-list"-->
                <!--                                 :options="dragOptions" ghostClass="ghost"-->
                <!--                                 animation="500" draggable=".drag-item"-->
                <!--                                 :disabled="!!dataList[dI].list.length"-->
                <!--                                 @add="handleAdd($event, dI)"-->
                <!--                      >-->
                <!--                        <div class="drag-item" v-for="(t, tI) in dataList[dI].list" :key="t.id">-->
                <!--                          <template-card :ref="`tCard_${dI}_${tI}`"-->
                <!--                                         :data="t" grid-->
                <!--                                         :index="dI + 999" :rowspan="item.h" :colspan="item.w">-->
                <!--                          </template-card>-->
                <!--                        </div>-->
                <!--                      <button @click="$alert('abc')">test</button>-->

                <div class="drag-item" style="color: white">
                  <template-card
                    :ref="`tCard_${dI}_0`"
                    :data="item"
                    grid
                    disabled
                    :index="dI + 999"
                    :rowspan="item.h"
                    :colspan="item.w"
                  >
                  </template-card>
                </div>
                <!--                      </draggable>-->
                <!--                  <div class="controls">-->
                <!--                    <span class="control flex-center" @click="onResize(item)"><i class="el-icon el-icon-d-caret"/></span>-->
                <!--                  </div>-->
              </div>
            </grid-item>
          </template>
          <!--                </draggable>-->
        </grid-layout>
      </div>
    </div>

    <!--    <el-drawer :visible.sync="showSetting" append-to-body>-->
    <!--      <div class="demo-drawer__content flex-col">-->
    <!--        <el-form :model="form" label-position="top">-->
    <!--          <el-form-item label="">-->
    <!--            <-->
    <!--          </el-form-item>-->
    <!--        </el-form>-->
    <!--        <div class="demo-drawer__footer">-->
    <!--          <el-button @click="cancelForm">取 消</el-button>-->
    <!--          <el-button type="primary" @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </el-drawer>-->
  </div>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
// 获取对应页面配置
import { getHome, getMemberStats, getOrderStats} from "@/modules/big-data/api/design";
import TemplateCard from "@/modules/big-data/components/TemplateCard";
// 监听宽度变化事件
import { addListener, removeListener } from "resize-detector";
import { getOverviewPageConfig } from "@/modules/big-data/api/base";

export default {
  components: {
    TemplateCard,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  data() {
    return {
      loading: true,
      dataList: [],
      colNum: 4,
      colWidth: 0,
      colHeight: 306,
      layerWidth: 0,
      // 浏览器视口宽高
      windowHeight: 0,
      windowWidth: 0,
      // scaleRatio: 1,
      scaleRatioByHeight: 1,
      // scaleRatioByWidth: 1,
      gap: 9 || 16,
      gridWidth: 0,
      // 根容器元素
      container: "",
      // showSetting: false,
      // formLabelWidth: '',
      // form: {
      //   label: '',
      //   region: ''
      // },
      logo: "",
      // 当前的tab
      currentTabPage: "home",
    };
  },
  created() {
    this.init();
    // 获取logo
    getOverviewPageConfig()
      .then((res) => {
        this.logo = res.data.logo;
      })
      .catch((err) => {});
  },
  mounted() {
    this.container = document.querySelector("#layer-box");

    this.queryColWidth();
    // 添加resize事件
    this.addResize();
  },
  methods: {
    // 添加resize事件
    addResize() {
      addListener(this.container, this.onBarResize);
    },
    // 监听容器大小变化，使 template-card 组件自适应
    onBarResize() {
      console.log("resize");
      this.queryColWidth();
      this.onGridItemResize();
    },
    // 移除监听resize
    removeResize() {
      removeListener(this.container, this.onBarResize);
    },
    // 触发每个小格子的resize事件
    onGridItemResize() {
      this.dataList.forEach((d, dI) => {
        const refArr = this.$refs[`tCard_${dI}_0`];
        if (refArr?.length) {
          refArr[0].resize();
        } else if (refArr) {
          if (refArr.resize) refArr.resize();
        }
      });
    },
    /** 查询最小单位格子宽度 */
    queryColWidth() {
      // 获取视口宽高
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;

      // this.scaleRatioByWidth = this.windowWidth / 1920
      // 根据高度调整缩放比例
      this.scaleRatioByHeight = this.windowHeight / 1080;

      const headerH =
        88 * (this.scaleRatioByHeight > 1 ? 1 : this.scaleRatioByHeight);
      // 容器内每个格子的高度
      this.colHeight = (this.windowHeight - headerH - this.gap * 4) / 3;
      // 每个格子的宽度
      this.colWidth = (this.colHeight * 460) / 306;
      // 容器每一列的宽度
      this.gridWidth = this.colWidth * 4 + this.gap * 5;
    },
    // 初始化
    init() {
      let api = "";
      switch (this.currentTabPage) {
        // 首页
        case "home":
          api = getHome;
          break;
        // 会员分析
        case "memberStats":
          api = getMemberStats;
          break;
        case "orderStats":
          api = getOrderStats
      }
      // 获取首页配置
      api()
        .then((res) => {
          // 拿到最新的更新时间
          this.update_time = res.data.update_time;
          // 拿到最新的组件列表
          if (res.data.component_list.length) {
            // TODO: 处理已保存的数据并回显
            this.dataList.push(...res.data.component_list);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 切换tab
    switchTab(tab) {
      this.currentTabPage = tab;
      this.dataList = [];
      this.loading = true;
      this.init();
    },
    toDesign(tab) {
      let routerData = this.$router.resolve({
        name: "BigDataDesign",
        params: {
          tab: tab || "home",
        },
      });
      window.open(routerData.href, "_blank");
    },
    /* 进入/退出全屏 */
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      if (this.isFullscreen) {
        this.fullScreen();
      } else {
        this.exitScreen();
      }
    },
    // 全屏
    fullScreen() {
      var el = document.documentElement;
      var rfs =
        el.requestFullScreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullscreen;
      if (typeof rfs != "undefined" && rfs) {
        rfs.call(el);
      }
    },
    // 退出全屏
    exitScreen() {
      var el = document.documentElement;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      if (typeof cfs != "undefined" && cfs) {
        cfs.call(el);
      }
    },
    /**
     * 关闭弹窗
     */
    closeWindow() {
      this.$message.info("退出编辑");
      window.opener = null;
      window.close();
    },
  },
};
</script>

<style lang="scss" scoped>
$header-h: 88px;

.container {
  margin: calc(-#{$space} * 2);
  background-color: #062326;
  /*background-color: whitesmoke;*/
  color: white;
  --bg-color: rgba(3, 23, 26, 1);
  --hover-color: rgba(75, 210, 237, 1);

  /* 全屏 */
  &.fullscreen {
    position: fixed;
    left: calc(#{$space} * 2);
    top: calc(#{$space} * 2);
    height: 100vh;
    width: 100%;
    z-index: 11;
    /*display: flex;*/
    /*justify-content: center;*/
  }

  .origin {
    /*width: 1920px;*/
    /*height: 1080px;*/
    /*transform-origin: left top;*/
  }
}

.origin {
  /*position: relative;*/
  /*    padding-top: $header-h;*/
}

.header {
  width: 100%;
  /*width: 1920px;*/
  height: $header-h;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.04);
  background: linear-gradient(
    180deg,
    rgba(75, 210, 237, 0.02) 0%,
    rgba(75, 210, 237, 0.1) 100%
  );
  background-image: url("~@/modules/big-data/assets/head-bg.png"),
    url("~@/modules/big-data/assets/head-logo-bg.png");
  background-repeat: no-repeat;
  background-size: auto 100%;
  transform-origin: left top;
  position: fixed;
  top: 0;
  min-width: 100vw;
  padding-left: 42px;

  /*box-shadow: 0 4px 4px 0 rgba(75,210,237,0.1);*/

  .title {
    height: 55px;
    margin-left: $space;
  }

  .logo {
    height: 37px;
    widows: 150px;
  }

  .btn {
    padding: 0 $space;
    font-size: 14px;
    @include no-select();

    .icon {
      width: 20px;
      height: 20px;
    }

    .el-icon {
      font-size: 22px;
    }

    .text {
      font-size: 16px;
      margin-top: 6px;
    }
  }

  .page-btn {
    width: 130px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    background: url("../assets/nav-btn.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    &.active {
      background-image: url("../assets/nav-btn-active.png");
      background-size: 100% 100%;

      font-weight: 700;
      // background-color: orange;
    }
  }
  .page-btn + .page-btn {
    margin-left: 45px;
  }
}

.layer {
  width: 100%;
  height: 100%;
  transform-origin: center top;

  .stage {
    /*margin: 0 auto;*/
  }

  .stage-item {
    /*background-color: rgba(4, 39, 45, 0.7);*/
    /*background-color: #062229;*/
    /*     min-height: $row-h;*/
    transition: border 0.3s;
    position: relative;
    height: 100%;
    /*border: 1px solid rgba(4, 39, 45, 0.7);*/
    /*border-width: 1px;*/
    /*border-style: solid;*/
    /*border-color: #062229;*/
  }

  .drag-item {
    height: 100%;
    overflow: hidden;
  }

  ::v-deep .template-card {
    padding: 0;
    width: 100%;
    height: 100%;
    border: none;
    background-color: var(--bg-color);

    .preview {
      background-color: unset;
      height: 100%;

      &:before {
        padding-top: 0;
      }

      .content {
        position: relative;
      }
    }

    & > .text {
      display: none;
    }
  }
}
</style>