var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"container fullscreen",attrs:{"id":"layer-box"}},[_c('div',{staticClass:"origin",style:({})},[_c('div',{staticClass:"header flex-between",style:({
        width: _vm.windowWidth / _vm.scaleRatioByHeight + 'px',
        transform: ("scale(" + (_vm.scaleRatioByHeight > 1 ? 1 : _vm.scaleRatioByHeight) + ")"),
      })},[_c('div',{staticClass:"left flex flex-align-center"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.logo}}),_c('img',{staticClass:"title",attrs:{"src":require("../assets/h1.png")}})]),_c('div',{staticClass:"middle flex-align-center"},[_c('div',{staticClass:"page-btn flex-col",class:[_vm.currentTabPage === 'home' ? 'active' : ''],on:{"click":function($event){return _vm.switchTab('home')}}},[_vm._v(" 首页 ")]),_c('div',{staticClass:"page-btn flex-col",class:[_vm.currentTabPage === 'memberStats' ? 'active' : ''],on:{"click":function($event){return _vm.switchTab('memberStats')}}},[_vm._v(" 会员分析 ")]),_c('div',{staticClass:"page-btn flex-col",class:[_vm.currentTabPage === 'orderStats' ? 'active' : ''],on:{"click":function($event){return _vm.switchTab('orderStats')}}},[_vm._v(" 财务分析 ")])]),_c('div',{staticClass:"right flex"},[_c('div',{staticClass:"btn flex-col flex-center cursor-pointer",on:{"click":function($event){return _vm.$message.info('敬请期待')}}},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/download.png")}}),_c('span',{staticClass:"text"},[_vm._v("导出数据")])]),_c('div',{staticClass:"btn flex-col flex-center cursor-pointer",on:{"click":function($event){return _vm.toDesign()}}},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/decorate.png")}}),_c('span',{staticClass:"text"},[_vm._v("页面装修")])]),_c('div',{staticClass:"btn flex-col flex-center cursor-pointer",on:{"click":_vm.toggleFullscreen}},[_c('i',{staticClass:"el-icon el-icon-full-screen"}),_c('span',{staticClass:"text"},[_vm._v("全屏")])]),_c('div',{staticClass:"btn flex-col flex-center cursor-pointer",on:{"click":function($event){return _vm.closeWindow()}}},[_c('i',{staticClass:"el-icon el-icon-close"}),_c('span',{staticClass:"text"},[_vm._v("关闭")])])])]),_c('div',{staticClass:"header-copy",style:({
        height: 88 * (_vm.scaleRatioByHeight > 1 ? 1 : _vm.scaleRatioByHeight) + 'px',
      })}),(!_vm.dataList.length)?_c('div',{staticClass:"flex-center",style:({
        height: ("calc(100vh - " + (88 * (_vm.scaleRatioByHeight > 1 ? 1 : _vm.scaleRatioByHeight)) + "px)"),
      })},[_c('el-button',{attrs:{"type":"plain"},on:{"click":function($event){return _vm.toDesign()}}},[_vm._v("页面装修")])],1):_vm._e(),_c('div',{staticClass:"layer",style:({
        width: _vm.colWidth ? (_vm.gridWidth + "px") : '',
        // 用 windowHeight 算出 row-height，从而推算出 row-width，如果 grid-width 超过 windowWidth，就需要进行缩放啦
        transform:
          _vm.colWidth && _vm.windowHeight && _vm.gridWidth > _vm.windowWidth
            ? ("scale(" + (_vm.windowWidth / _vm.gridWidth) + ")")
            : '',
        // 居中
        marginLeft: (_vm.windowWidth - _vm.gridWidth) / 2 + 'px',
      }),attrs:{"id":"content-box"}},[_c('grid-layout',{ref:"gridlayout",staticClass:"stage grid",style:({
          // width: colWidth ? `${colWidth * 4 + 9 * 5}px` : '',
          // marginLeft: -(1920 - ((colWidth * 4 + 9 * 5))) + 'px'
        }),attrs:{"id":"content","layout":_vm.dataList,"col-num":_vm.colNum,"row-height":_vm.colHeight,"is-draggable":false,"is-resizable":false,"is-mirrored":false,"is-bounded":true,"vertical-compact":true,"margin":[_vm.gap, _vm.gap],"use-css-transforms":true,"max-rows":3,"auto-size":true},on:{"update:layout":function($event){_vm.dataList=$event}}},[_c('div',{staticClass:"before",style:({
            backgroundSize: ("calc(calc(100% - " + _vm.gap + "px) / 4) " + ((_vm.colWidth * 181) / 269 + _vm.gap) + "px"),
          })}),_vm._l((_vm.dataList),function(item,dI){return [_c('grid-item',{key:item.i,attrs:{"x":item.x,"y":item.y,"w":item.w,"h":item.h,"i":item.i}},[_c('div',{staticClass:"stage-item"},[_c('div',{staticClass:"drag-item",staticStyle:{"color":"white"}},[_c('template-card',{ref:("tCard_" + dI + "_0"),refInFor:true,attrs:{"data":item,"grid":"","disabled":"","index":dI + 999,"rowspan":item.h,"colspan":item.w}})],1)])])]})],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }